import Portfolio from "../../component/templates/Portfolio";

type props = {};

const Home: React.FC<props> = () => {
  return (
    <>
      <Portfolio />
    </>
  );
};
export default Home;
