import HeroBanner from "../../organisms/HeroBanner"

type props = {}

const Portfolio: React.FC<props> = () => {
  return (
    <>
      <HeroBanner />
    </>
  )
}
export default Portfolio